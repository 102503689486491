import * as React from 'react';
import {
    CircularProgress,
    Tooltip, useTheme,
} from "@material-ui/core";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks" ;
import {
    appContextSelector,
} from "../../../app-context/appContextSlice";
import {getLocalizedString} from "../../../../util/Localization";
import {ARCButton} from "../../../ui-components/design-system/ARCButton";
import {DeviceTypeDto} from "../../../../dtos/DeviceType";
import {ARCSelectInput} from "../../../ui-components/design-system/ARCSelectInput";
import {bulkUpdateDeviceType, deviceContextSelector} from "../../deviceSlice";
import {deviceTypeSelector} from "../../deviceTypeSlice";
import "./BulkDeviceAction.scss"

type BulkDeviceActionProps = {
    bulkDeviceList: {
        [deviceId: number] : boolean
    },
    resetBulkList: () => void,
    deviceTypeList: DeviceTypeDto[]
}


export const BulkDeviceAction:React.ComponentType<BulkDeviceActionProps> = ({bulkDeviceList, resetBulkList, deviceTypeList}) => {
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const deviceTypeContext = useAppSelector(deviceTypeSelector);
    const deviceContext = useAppSelector(deviceContextSelector);
    const theme = useTheme();
    
    const BULK_ACTION_DEVICE_TYPE = getLocalizedString('bulkTask.updateDeviceType', 'Update Device Type');
    const BULK_ACTION_LIST : string[] = React.useMemo(() => {
        let actions = [];

        actions.push(BULK_ACTION_DEVICE_TYPE);

        return actions;
    }, [BULK_ACTION_DEVICE_TYPE]);
    
    const bulkActionOptions = React.useMemo(() => {
        const options = BULK_ACTION_LIST.map(action => {
            return {
                value: action,
                label: action
            }
        });
        return options;
    }, [BULK_ACTION_LIST]);
    
    const deviceTypesList = React.useMemo(() => {
        const options = deviceTypeList.map(dev => {
            return {
                value: dev.deviceTypeId,
                label: dev.deviceTypeName
            }
        });

        return [
            {
                value: undefined,
                label: getLocalizedString('bulkTask.selectDeviceType', 'Select Device Type')
            },
            ...options
        ];
    }, [deviceTypeList]);
    
    type BulkAction = typeof BULK_ACTION_DEVICE_TYPE | undefined;

    const [selectedAction, setSelectedAction] = React.useState<BulkAction | undefined>(BULK_ACTION_DEVICE_TYPE);
    const [selectedDeviceTypeId, setSelectedDeviceTypeId] = React.useState<number | undefined>(undefined);
    
    const selectedDeviceList = React.useMemo(() => {
        return Object.keys(bulkDeviceList).map(Number);
    }, [bulkDeviceList]);
    
    const onSubmit = React.useCallback(async () => {
        if(context.currentLocation?.locationId && selectedDeviceTypeId) {
            switch (selectedAction) {
                case BULK_ACTION_DEVICE_TYPE:
                    await dispatch(bulkUpdateDeviceType({
                        locationId: context.currentLocation.locationId,
                        deviceList: selectedDeviceList,
                        deviceTypeId: selectedDeviceTypeId
                    })).then(resetBulkList)
                    break;
            }
        }
    }, [context.currentLocation?.locationId, selectedAction, BULK_ACTION_DEVICE_TYPE, dispatch, resetBulkList, selectedDeviceList, selectedDeviceTypeId])
    
    const deviceTypeTooltip = React.useMemo(() => {
        let returnString = '';
        if (context.currentLocation?.serialIsDevice && deviceTypeContext.deviceTypes.length > 0) {
            returnString = getLocalizedString('bulkTask.serialIsDevice.tooltip1', 'Device Types using Serial Numbers as Device IDs are not supported. To update devices to Device Type(s)') + ', ';
            deviceTypeContext.deviceTypes.forEach((dt) => returnString += dt.serialIsDevice ? dt.deviceTypeName + ', ' : '')
            returnString += getLocalizedString('bulkTask.serialIsDevice.tooltip2', 'please edit Devices individually.')
        }
        return returnString;
    }, [context.currentLocation?.serialIsDevice, deviceTypeContext.deviceTypes])
    
    return (
        <div className={'bulk-device-action__container'}>

            <div className={'section-header'}>
                {getLocalizedString('bulkTask.create', 'Create Bulk Task')}

                {deviceContext.bulkActionLoading &&
                    <CircularProgress style={{margin: '0 0 0 15px'}} size={30}/>
                }
            </div>

            <div className='u-display--flex u-display--flex-row u-flex-wrap u-align-items--end u-gap-sm'>
                <div className={'bulk-device-action__form-input'}>
                    <div className={'field-text'} style={{ width: '20vw', maxWidth: '250px', minWidth: '150px', marginRight: '3vw', color: theme.palette.primary.main}}>
                        {getLocalizedString('bulkTask.task', 'Bulk Task')}
                    </div>

                    <ARCSelectInput
                        id={'view-devices-bulk-task-select-task-input'}
                        size={'md'}
                        value={selectedAction}
                        style={{
                             width: '25vw',
                             maxWidth: '300px',
                             minWidth: '200px',
                             height: '40px',
                             margin: '0 22px 0 .5vw',
                             padding: '2px 10px 2px 10px'}}
                        options={bulkActionOptions}
                        disabled={true}
                        onChange={(option) => {
                            const val = option.value as BulkAction;
 
                            if(val) {
                                setSelectedAction(val);
                            } else {
                                setSelectedAction(undefined);
                            }
                        }}
                   />
                </div>
                
                <Tooltip
                    placement={'left'} 
                    title={
                        deviceTypeTooltip &&
                        <div style={{margin: '0', maxWidth: '450px'}}>
                            {deviceTypeTooltip}
                        </div>
                    } 
                >
                <div className={'bulk-device-action__form-input'}>
                    <div className={'field-text'} style={{color: theme.palette.primary.main}}>{getLocalizedString('bulkTask.deviceTypes.label',  'Change Type to')}</div>
                    <ARCSelectInput
                        id={'view-devices-bulk-task-select-location-input'}
                        size={'md'}
                        style={{width: '25vw',
                            maxWidth: '300px',
                            minWidth: '200px',
                            height: '40px',
                            margin: '0 22px 0 .5vw',
                            padding: '2px 10px 2px 10px'}}
                        value={selectedDeviceTypeId}
                        className={'field-text'}
                        options={deviceTypesList}
                        onChange={(option) => {
                            const val = option.value as number;
 
                            if(val) {
                                setSelectedDeviceTypeId(val);
                            } else {
                                setSelectedDeviceTypeId(undefined);
                            }
                        }}
                   />
                </div>
                </Tooltip>
                

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <ARCButton
                        fill={'filled'}
                        id={'view-devices-bulk-task-submit-button'}
                        size={'sm'}
                        variant={'primary'}
                        disabled={!selectedDeviceTypeId || Object.keys(bulkDeviceList).length === 0} onClick={onSubmit}
                        label={getLocalizedString('bulkTask.submit', 'Submit Bulk Task')}
                    >
                    </ARCButton>
    
                    {!(!selectedDeviceTypeId || Object.keys(bulkDeviceList).length === 0) &&
                        <ARCButton
                            fill={'outlined'}
                            id={'view-devices-bulk-task-clear-button'}
                            size={'sm'}
                            variant={'secondary'}
                            label={getLocalizedString('bulkTask.cancel', 'Clear')}
                            onClick={() => {setSelectedDeviceTypeId(undefined)}}
                        >
                        </ARCButton>
                    }
                </div>
            </div>
        </div>
    );
}
